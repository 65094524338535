import React, { useState } from "react"
import styled, { css } from "styled-components"

import { Typography, TextField } from "@material-ui/core"
import { useMutation } from "@apollo/client"

import { SEND_PASSWORD_RESET_EMAIL_MUTATION } from "apollo/mutation"
import Edges from "components/edges"
import Button from "components/button"

import * as theme from "theme"

export default props => {
  const [values, setValues] = useState({ username: "" })
  const [message, setMessage] = useState({})

  const [executeSendResetEmail, { loading }] = useMutation(
    SEND_PASSWORD_RESET_EMAIL_MUTATION,
    {
      onCompleted: res => {
        const { sendPasswordResetEmail } = res

        sendPasswordResetEmail?.user &&
          setMessage({
            type: "success",
            text: `An email has been sent to ${values?.username} with further instructions.`,
          })
      },
      onError: error => {
        const { graphQLErrors } = error
        graphQLErrors &&
          graphQLErrors.map(({ message }) =>
            setMessage({ type: "error", text: message })
          )
      },
    }
  )

  const handleChange = ({ target: { name, value } }) => {
    // control the form input values with state
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = e => {
    // prevent default form submission
    e.preventDefault()

    // clear form messages
    setMessage({})
    if (!values?.username) {
      return
    }

    // execute update user mutation with the input values from the state
    executeSendResetEmail({
      variables: { ...values },
    })
  }

  return (
    <Container {...props}>
      <Edges size="md">
        <Typography variant="h2" gutterBottom>
          Forgot password
        </Typography>

        <Form onSubmit={handleSubmit} id="forgotPasswordForm">
          {message && (
            <Message type={message.type}>
              <Typography>{message.text}</Typography>
            </Message>
          )}
          <TextField
            fullWidth
            autoComplete="email"
            label="Email"
            type="email"
            name="username"
            onChange={handleChange}
            value={values.username}
          />
        </Form>
        <FormControls>
          <Button
            disabled={loading || !values?.username}
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            form="forgotPasswordForm"
          >
            Reset Password
          </Button>
        </FormControls>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  padding: 30px 0;
`
const Form = styled.form`
  max-width: 650px;
`

const Message = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 5px;
  ${({ type }) => {
    switch (type) {
      case "success":
        return css`
          color: ${theme.colors.success};
          border: 1px solid green;
        `
      case "error":
        return css`
          color: ${theme.colors.error};
          border: 1px solid red;
        `
      default:
        return null
    }
  }}
`

const FormControls = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`
